import * as React from "react";
import { Header, Image, Grid } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const pageTitle = "404 Not found";

const NotFoundPage: React.FC = () =>
    <Grid centered verticalAlign="middle"
      style={{
        minHeight: "700px",
        }}
      >
      <Grid.Column>
        <Grid.Row style={{textAlign: "center"}}>
          <Image src="/images/komarikumo.png" centered size="tiny" alt="cloud with troubled face"/>
          <Header as="h1">#404</Header>
          <Header as="h2">Page Not Found</Header>
        </Grid.Row>
      </Grid.Column>
    </Grid>;

export default withLayout(NotFoundPage, pageTitle);
